import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";
import Detail from "@/views/Detail.vue";
import Payment from "@/views/Payment.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },


  {
    path: "/",
    name: "Detail",
    component: Detail,
  },

  {
    path: "/",
    name: "Payment",
    component: Payment,
  },


  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
