<script>
import { computed, inject, watchEffect, getCurrentInstance } from "vue";

export default {
  name: "Tab",
  setup() {
    const instance = getCurrentInstance();
    const { tabs, selectTab, active } = inject("tabsState", {
      tabs: [],
      selectTab: () => {},
    });
    const index = computed(() =>
      tabs.value.findIndex((target) => target.uid === instance.uid)
    );
    const isActive = computed(() => index.value === active.value);

    const activeteTab = () => {
      selectTab(index.value);
    };

    watchEffect(() => {
      if (index.value === -1) {
        tabs.value.push(instance);
      }
    });

    return {
      activeteTab,
      isActive,
    };
  },
};
</script>
<style scoped>
.box-content {
  box-sizing: content-box;
}

.border-b-2 {
  border-bottom-width: 2px;
}
.border-green-500 {
  border-bottom: 4px solid var(--color) !important;
}

.text-color {
  color: var(--color) !important;
}

.box-active {
  background: white;
}

.text-align {
  text-align: center;
}
.w-25 {
  width: 25%;
}
.py-3 {
  padding-top: 0.75rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 0.75rem;
}
.rounded-tl-md {
  border-top-left-radius: 0.5rem;
}
.rounded-tr-md {
  border-top-right-radius: 0.5rem;
}
.overflow-hidden {
  overflow: hidden;
}
</style>

<template>
  <div
    :class="isActive ? 'box-active box-content text-color' : 'border-white'"
    class="text-align w-25 py-3 rounded-tl-md rounded-tr-md overflow-hidden cursor-pointer text-white"
    @click="activeteTab"
  >
    <slot />
  </div>
</template>
