<template>
  <div class="bg-home vh100" style="background-color: white;">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />

    <div class="card-header text-center">

      <div class="container">
        <div class="row">
          <div class="col-6 text-start">
            <img src="@/assets/logo.png" style="height: 40%; margin-top: 3%;" />
          </div>
          <div class="col-6 text-end">
            <div class="mt-4 font-weight-light text-white">
              <h1 style="font-size: 2.8em !important;">ออร์เดอร์</h1>
            </div>
            <div class="en t2 font-weight-light text-white">
              <h6>ORDER</h6>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="card-body p-0">
      <Orderhistory></Orderhistory>


    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject } from "vue";
import liff from "@line/liff";


import Orderhistory from "@/components/Order/Orderhistory";
export default defineComponent({
  name: "Profile",
  components: {
    Loading,
    Orderhistory
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;
    const SendLocation = ref(null);

    onMounted(() => {
      SendLocation.value = store.getters.getSendlocation;
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    return {
      fn_status,
      ProfireLine,
      Profire,
      close
    };
  },
});

</script>

<style scoped>
.card-header {
  border-bottom: none;
  background-color: transparent;
  height: 100px;
  min-height: 100px;
}

.rank_box {
  border: none;
  border-radius: 25px;
  background-color: #707070;
  padding: 8px 8px 1px 8px;
}

.address-box {
  background-color: #BED630;
  min-height: 300px;
  overflow: scroll;
  padding: 16px;

}

.address-box-header {
  position: relative;
}

.address-box-body {
  position: relative;
  min-height: 250px;
  height: 250px;
  overflow-y: scroll;

}
</style>
