import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default function useSweetalert() {
  const Sconfirm = (text, icon, cancle_bt, confirm_bt) => {
    return Swal.fire({
      reverseButtons: true,
      padding: "1em",
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#97CB5B",
      confirmButtonText: confirm_bt,
      cancelButtonText: cancle_bt,
      customClass: {
        container: "font-size-20",
      },
    });
  };
  
  const SconfirmNc = (text, icon, textbt) => {
    return Swal.fire({
      padding: "2em",
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText: textbt,
      confirmButtonColor: "#009EF7",
      customClass: {
        confirmButton: "btn fw-bold btn-light-primary",
      },
    });
  };

  const SToast = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon,
      title: title,
    });
  };

  return { Sconfirm, SToast, SconfirmNc };
}
