export const SET_ORDERS = "setorders";
export const SET_ORDER_DETAIL = "setorderDetail";
export const SET_ORDER_DETAIL_ADDR = "setorderDetailAddr";

export default {
  state: {
    orders: [],
    order_detail: [],
    order_detail_addr: [],
  },
  getters: {
    getOrders(state) {
      return state.orders;
    },
    getOrderdetail(state) {
      return state.order_detail;
    },
    getOrderdetailAddr(state) {
      return state.order_detail_addr;
    }
  },
  actions: {
    [SET_ORDERS](state, payload) {
      state.commit(SET_ORDERS, payload);
    },
    [SET_ORDER_DETAIL](state, payload) {
      state.commit(SET_ORDER_DETAIL, payload);
    },
    [SET_ORDER_DETAIL_ADDR](state, payload) {
      state.commit(SET_ORDER_DETAIL_ADDR, payload);
    }
  },
  mutations: {
    [SET_ORDERS](state, payload) {
      state.orders = {
        ...state.orders,
        ...payload,
      };
    },
    [SET_ORDER_DETAIL](state, payload) {
      state.order_detail = {
        ...payload,
      };
    },
    [SET_ORDER_DETAIL_ADDR](state, payload) {
      state.order_detail_addr = {
        ...payload,
      };
    }
  },
};
