import ApiService from "@/service/ApiService";

export default function useOrder() {

  
  const getOrder_by_id = async (id) => {
    return await ApiService.get("getorder_by_id/" + id);
  };

  const getOrder_by_order_id = async (id) => {
    return await ApiService.get("getorder_by_order_id/" + id);
  };

 
  const updateOrder = async (data) => {
    return await ApiService.put("update_order/" + data.id_line, data);
  };

  const cancleOrder = async (data) => {
    return await ApiService.post("cancle_order/", data);
  };

   
  const updateOrderPayment = async (data) => {
    return await ApiService.post("update_order_payment/", data);
  };




  return { 
    getOrder_by_id,
    getOrder_by_order_id,
    updateOrder,
    updateOrderPayment,
    cancleOrder
  };
}
