<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
  <div class="vh100 pb-4">

    <div class="sub_header position-relative pt-3">

      <div class="back_button_box" @click="back">
        <img src="~@/assets/back.png" style="height: 20px;" />
      </div>

      <div class="product-title-text text-center">รายละเอียดการสั่งซื้อ</div>

      <!-- 
      <div class="close_button_box" @click="$router.go(-1)">
        <img src="~@/assets/x.png" style="height: 20px;" />
      </div> -->


    </div>




    <div class="sub_body text-black w100 mb-2 top-shadow-box">
      <div class="position-relative" v-for="data in Order_detail.order_detail" :key="data.id">
        <div class="cart-item mb-3 mt-3">
          <div class="justify-content-start align-item-center d-flex">
            <img class="ms-2 cart-pic" src="~@/assets/product_pic.png" />
            <span style="width: 80%;">
              <div class="row ms-2 ">
                <div class="col-12">
                  <div class="product-subtitle-text">{{ data.products.name }}</div>

                </div>
              </div>
              <div class="row ms-2">
                <div class="col-6">
                  <div class="product-detail-text">จำนวน {{ data.product_quantity }}</div>
                </div>
              </div>
              <div class="row ms-2">
                <div class="col-6">
                  <div class="product-subtitle-text">{{ data.product_sum_price }} ฿</div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="line" style="background-color: #D9D9D9; width: 100% !important;"></div>
    </div>

    <div class="fotter ps-3 pe-3">
      <div class="row mb-3 justify-content-center">
        <div class="product-title-text" style="font-size:18px">ที่อยู่สำหรับจัดส่ง</div>
      </div>

      <div class="address-box mt-3 pb-3 mb-2">

        <div class="address-box-body pb-2">
          <div class="row justify-content-between font-weight-normal ps-4 pe-4 mb-2">
            <div class="col-6 ">
              <span style="font-size: 18px; padding-left: 5px;">{{ Order_detail.order_send_address.fullname }}</span>
            </div>
            <div class="col-6 text-end">
              <span style="font-size: 18px; margin-right: 5px">{{ Order_detail.order_send_address.tel }}</span>
            </div>

            <div class="col-12 position-relative mt-2" style="font-size: 16px;">
              {{ Order_detail.order_send_address.numberhome }} ม.{{ Order_detail.order_send_address.moo }} {{
                Order_detail.order_send_address.alleyway }} {{ Order_detail.order_send_address.district }} {{
    Order_detail.order_send_address.amphoe }}
              {{ Order_detail.order_send_address.province }} {{ Order_detail.order_send_address.zipcode }}
            </div>
          </div>
        </div>

      </div>


      <div class="line" style="background-color: #D9D9D9; width: 100% !important;"></div>


      <div class="row mb-3 justify-content-center p-4">

        <div class="col-3">
          <img src="~@/assets/logo_small_g.png" style="height: 75px;" />
        </div>
        <div class="col-9 text-end">
          <div class="product-subtitle-text">มูลค่าสินค้า {{ Order_detail.order_sum_price }} ฿</div>
          <div class="product-subtitle-text">ค่าจัดส่ง 60 ฿</div>
          <div class="product-title-text" style="font-size: 1.4rem; font-weight: 500;">มูลค่ารวมทั้งหมด {{
            Order_detail.order_sum_price + 60
          }} ฿</div>
        </div>

      </div>



      <div class="row pb-4 ms-5 me-5 justify-content-center" v-if="Order_detail.order_status_id == 1">
        <button class="bt-main" @click="pay">
          ชำระเงิน
        </button>
      </div>


    </div>


  </div>
</template>

<script>
import liff from "@line/liff";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
//import useSweetalert from "@/service/sweetalert2";
import { defineComponent, onMounted, inject, ref, computed } from "vue";
export default defineComponent({
  name: "Summary",
  components: {
    Loading,
  },
  setup() {
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    // const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    const SendLocation = ref(null);
    const selectedLocation = ref(null);



    onMounted(() => {
      SendLocation.value = store.getters.getSendlocation;
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });


    const back = () => {
      router.push({ name: "Dashboard" });
    }

    const pay = () => {
      router.push({ name: "Payment" });
    }


    const Order_detail = computed(() => {
      return store.getters.getOrderdetail
    });


    const addSendLocation = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_LIFF_REGISTER}`,
          external: false,
        });
      });

    }


    // const confirmOrder = () => {

    //   if (selectedLocation.value) {

    //     let send_data = {};
    //     send_data.order_sum_price = store.getters.cartTotal;
    //     send_data.product = store.getters.getCart;
    //     send_data.id_line = store.getters.getAccessToken.accesstoken;
    //     send_data.order_send_address = selectedLocation.value;



    //     Sconfirm("ยืนยันการสั่งสินค้า ?", "question", "ยกเลิก", "ตกลง").then(async (result) => {
    //       if (result.isConfirmed) {
    //         isLoading.value = true;

    //         await sentOrder(send_data)
    //           .then((result) => {
    //             isLoading.value = false;
    //             if (result.data.status === "Success") {
    //               SconfirmNc("สั่งซื้อสำเร็จ <br> สามารถตรวจสอบข้อมูลได้ที่เมนู ออรืเดอร์", "success", "ตกลง").then(() => {
    //                 // if (store.getters.getUrlCallBack.id) {
    //                 //   liff.ready.then(() => {
    //                 //     liff.openWindow({
    //                 //       url: store.getters.getUrlCallBack.id,
    //                 //       external: false,
    //                 //     });
    //                 //   });
    //                 // } else {
    //                 //   router.push({ name: "Home" });
    //                 // }
    //                 liff.ready.then(() => {
    //                   liff.closeWindow();
    //                 });
    //               });
    //             } else {
    //               SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
    //                 () => {
    //                   liff.ready.then(() => {
    //                     liff.closeWindow();
    //                   });
    //                 }
    //               );
    //             }
    //           })
    //           .catch((error) => {
    //             if (error.response) {
    //               let errors = error.response.data.errors;

    //               if (errors.id_line) {
    //                 SToast("error", `${errors.id_line[0]}`);
    //               } else {
    //                 SToast("error", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
    //               }
    //             }
    //           });
    //         //outer.push("/Home")
    //       }
    //     });
    //   } else {
    //     SconfirmNc("กรุณาเลือกที่อยู่", "error", "ตกลง")
    //   }

    //}




    return {
      Order_detail,
      SendLocation,
      selectedLocation,
      isLoading,
      color,
      addSendLocation,
      back,
      pay
    };
  },
});
</script>

<style scoped>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* Button styles */


boder-box {
  border: 2px solid var(--color);
  padding: 5px;
}

boder-box {
  border: 2px solid var(--color);
  padding: 5px;
}

.cart-pic {
  height: 100%;
  width: 100px;
}

.button-box {
  display: flex;
  height: 30px;
  width: 110px;
  border: solid 1px #919191;
  padding: 5px;
  border-radius: 20px;
  justify-content: space-between;
}

.button-box button {
  text-align: center;
  background-color: white;
  border: solid 1px #919191;
  border-radius: 50%;
  font-size: 16px;
  line-height: 2px;
  text-align: center;
}

.count-item {
  line-height: 20px;
  font-size: 18px;
  width: 30px;
  text-align: center;
}

button-box button {
  text-align: center;
  border: solid 1px #070707;
  border-radius: 50%;
}

.cart-box {
  display: flex;
  height: 50px;
  width: 100%;
  flex-direction: row;
}

/* .cart-box image {
  position: absolute;
  top: 0;
  left: 25px;
  width: 84px;
  height: 67px;
} */

.form-check-label {
  font-size: 16px !important;
}

.sub_header {
  padding: 0;
  width: 100%;
  height: 60px;
  min-height: 60px;
}

.sub_body {
  padding: 16px;
}


.logo_sub {
  position: absolute;
  top: 10px;
  left: 20px;
}

.close_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  right: 10px;
}

.back_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  left: 10px;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: var(--color);
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #8b9f6d;
}

.bt-main:active {
  background-color: #8b9f6d;
  transition: background-color 0s;
  opacity: 0.5;
}

.bt-sub-outline {
  width: 30%;
  min-height: 30px;
  border: solid 1px var(--color_sub);
  background-color: white;
  color: var(--color_sub);
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-sub-outline:hover {
  background-color: #f3f3f3;
}


.bt-sub-outline:active {
  background-color: #f3f3f3;
  transition: background-color 0s;
  opacity: 0.5;
}


.product-title-text {
  font-size: 1.5em;
  font-weight: 400;
}

.product-subtitle-text {
  font-size: 1.2em;
  font-weight: 400;
}

.product-detail-text {
  font-size: 1.0em;
  font-weight: 200;
}

.product-sammary-text {
  font-size: 2.5em;
  font-weight: 500;
}


.bt-edit {
  width: 100%;
  min-height: 60px;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  border: 1px solid #1d1c1d4d;

}

.bt-edit:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
}

.bt-edit:active {
  background-color: #e9e9e9;
  transition: background-color 0s;
  opacity: 0.5;
}</style>
