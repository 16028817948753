<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
  <div class="vh100 pb-4">

    <div class="sub_header position-relative pt-3">

      <div class="back_button_box" @click="back">
        <img src="~@/assets/back.png" style="height: 20px;" />
      </div>

      <div class="product-title-text text-center">ชำระเงิน</div>

      <!-- 
      <div class="close_button_box" @click="$router.go(-1)">
        <img src="~@/assets/x.png" style="height: 20px;" />
      </div> -->


    </div>




    <div class="sub_body text-black w100 mb-2 top-shadow-box">

      <div class="product-title-text text-start">ช่องทางการชำระเงิน</div>
      <div class="product-subtitle-text text-start">โอนชำระผ่าน Mobile Banking</div>
      <div class="product-subtitle-text text-start" style="font-size:14px; font-weight: 300;">กรุณาเลือกธนาคารของเรา
        ที่ท่านโอนชำระ</div>
    </div>

    <div class="fotter ps-3 pe-3">
      <div class="address-box mt-3">

        <div class="address-box-body pb-2">
          <div class="row justify-content-between font-weight-normal ps-4 pe-4 mb-2">

            <div class="col-12 mb-3 gender">
              <label class="form-check form-check-custom form-check-solid">

                <div class="row">
                  <div class="col-3">
                    <Field type="radio" name="bank" class="form-check-input" value="ktb" v-model="select_bank" />
                    <img :src="ktb" class="icon" alt="" />
                  </div>
                  <div class="col-9 form-check-label">
                    <h3> กรุงไทย </h3>
                    เลชที่บัญชี : 000-000-000-0
                  </div>
                </div>

              </label>
            </div>
            <div class="col-12 gender">
              <label class="form-check form-check-custom form-check-solid">
                <div class="row">
                  <div class="col-3">
                    <Field type="radio" name="bank" class="form-check-input" value="scb" v-model="select_bank" />
                    <img :src="scb" class="icon" alt="" />
                  </div>
                  <div class="col-9 form-check-label">
                    <h3> ไทยพานิชย์</h3>
                    เลชที่บัญชี : 000-000-000-0
                  </div>
                </div>
              </label>
            </div>
            <div class="fv-plugins-message-container mt-3">
              <div class="fv-help-block">
                <ErrorMessage name="bank" />
              </div>

            </div>

          </div>


        </div>
        <div class="row justify-content-center">
          <div class="line col-9" style="background-color: #D9D9D9;"></div>
        </div>

      </div>




      <div class="row justify-content-center p-4">

        <div class="product-title-text text-center" style="font-size: 1.5rem; font-weight: 500;">มูลค่ารวมทั้งหมด {{
          Order_detail.order_sum_price + 60
        }} ฿</div>

        <div class="col-8 text-center mt-2">
          <button type="button" class="btn btn-light" style="border-radius:25px;" v-on:click="toggle_confirm_slip()">
            แจ้งการโอนเงินได้ ที่นี่
          </button>

        </div>
      </div>


      <Transition>
        <div class="fotter ps-3 pe-3 text-center" v-if="confirm_slip == true">
          <Form @submit="onSubmitData" :validation-schema="validation_data" id="kt_account_profile_details_form"
            class="form" novalidate="novalidate">



            <div class="row g-2">
              <div class="col-5">
                <label class="form-label required fw-bold mt-2">ยอดเงิน</label>
              </div>
              <div class="col-6 justify-content-start">
                <Field type="text" name="pay_out" class="form-control form-control-solid" placeholder="ยอดเงิน" />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="pay_out" />
                  </div>
                </div>
              </div>

            </div>
            <!-- 
          {{pay_date}} -->


            <div class="row g-2">
              <div class="col-5">
                <label class="form-label required fw-bold mt-2">วันที่โอน</label>
              </div>
              <div class="col-6 justify-content-start">
                <Field type="hidden" name="pay_day" v-model="pay_date" />
                <Datepicker placeholder="วันที่โอน" :enableTimePicker="false" v-model="pay_date" cancelText="ยกเลิก"
                  selectText="เลือก">
                </Datepicker>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="pay_day" />
                  </div>
                </div>
              </div>
            </div>


            <!-- {{pay_time}} -->

            <div class="row g-2">
              <div class="col-5">
                <label class="form-label required fw-bold mt-2">เวลาที่โอน</label>
              </div>
              <div class="col-6 justify-content-start">
                <Field type="hidden" name="pay_time" v-model="pay_time" />
                <Datepicker timePicker placeholder="เวลาที่โอน" v-model="pay_time" cancelText="เวลาที่โอน"
                  selectText="ตกลง">
                </Datepicker>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="pay_time" />
                  </div>
                </div>
              </div>
            </div>

            <label class="col-12 required form-label required fw-bold">รูปภาพสลิปการโอนเงิน</label>
            <div class="container text-center">
              <div class="row ">

                <div v-if="imageUrl.length !== 0" class="imagePreviewWrapper" style="padding: 15px 0px">
                  <div class="row justify-content-center">

                    <div style="position: relative;" class="col-8" v-for="(item, index) in imageUrl" :key="index">
                      <div class="xx" @click="deleteImage(index)">
                        <span>x</span>
                      </div>
                      <img class="images" :src="item" />
                    </div>
                  </div>
                </div>
                <input type="file" id="getFilecamera" multiple="multiple" accept="image/jpg, image/jpeg ,image/png"
                  @input="pickFile" style="display: none" capture="camera" />
                <input type="file" id="getFile" multiple="multiple" accept="image/jpg, image/jpeg, image/png"
                  @input="pickFile" style="display: none" />
                <div class="d-flex justify-content-center">
                  <button type="button" @click="selectImage(1)" class="button-upload">
                    ถ่ายรูป<img src="@/assets/camera.png" class="icon-ima" />
                  </button>
                  <div style="padding-left: 5px; padding-right: 5px"></div>
                  <button type="button" @click="selectImage(2)" class="button-upload">
                    แนบรูปภาพ<img src="@/assets/picture.png" class="icon-ima" />
                  </button>

                  <Field type="hidden" name="imageUrl" v-model="imageUrl" />
                </div>



                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="imageUrl" />
                  </div>
                </div>

              </div>
              <!-- <label class="col-12 "> {{
                translate("booking_pay.confirm_payment_form.payment_notice")
            }}</label> -->
              <button class="button-submit mt-2 mb-3" type="submit">
                <div class="normal-text text-white">ยืนยัน</div>
              </button>
            </div>



          </Form>
        </div>

      </Transition>



    </div>


  </div>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import Datepicker from '@vuepic/vue-datepicker';
import useSweetalert from "@/service/sweetalert2";
import ktb from "@/assets/ktb.png";
import scb from "@/assets/scb.png";
import liff from "@line/liff";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ErrorMessage, Field, Form } from "vee-validate";
import { defineComponent, onMounted, inject, ref, computed } from "vue";
import * as Yup from "yup";
import moment from "moment";
import useOrder from "@/service/api/Order";
export default defineComponent({
  name: "Summary",
  components: {
    Loading,
    ErrorMessage,
    Field,
    Form,
    Datepicker
  },
  setup() {
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    const SendLocation = ref(null);
    const select_bank = ref(null);
    let confirm_slip = ref(false);
    const imageUrl = ref([]);
    const pay_date = ref();
    const pay_time = ref();

    const { updateOrderPayment } = useOrder();

    onMounted(() => {
      SendLocation.value = store.getters.getSendlocation;
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });



    function toggle_confirm_slip() {
      console.log("hello");
      if (confirm_slip.value == false) {
        confirm_slip.value = true;
      } else {
        confirm_slip.value = false;
      }
    }


    const back = () => {
      router.push({ name: "Dashboard" });
    }

    const pay = () => {
      router.push({ name: "Dashboard" });
    }


    const Order_detail = computed(() => {
      return store.getters.getOrderdetail
    });


    const addSendLocation = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_LIFF_REGISTER}`,
          external: false,
        });
      });

    }

    const validation_data = Yup.object().shape({

      pay_out: Yup.string().required("กรุณากรอก ยอดเงิน"),
      pay_day: Yup.string().nullable().required("กรุณาเลือก วันที่โอน"),
      pay_time: Yup.object().nullable().required("กรุณาเลือก เวลาที่โอน"),
      imageUrl: Yup.array().min(1, "กรุณาแนบรูปสลิป")
    });


    const onSubmitData = (values) => {

      if (select_bank.value) {

        values.id_line = store.getters.getAccessToken.accesstoken;
        values.select_bank = select_bank.value;
        values.id = store.getters.getOrderdetail.id;
        values.order_no = store.getters.getOrderdetail.order_no;
        values.pay_time = moment(values.pay_time.hours + ":" + values.pay_time.minutes + ":" + values.pay_time.seconds, "H:i:s")._i;



        Sconfirm("ยืนยันการชำระเงิน ?", "question", "ยกเลิก", "ตกลง").then(async (result) => {
          if (result.isConfirmed) {
            isLoading.value = true;

            await updateOrderPayment(values)
              .then((result) => {
                isLoading.value = false;
                if (result.data.status === "Success") {
                  SconfirmNc('การชำระเงินสำรเร็จ กรุณารอการยืนยันการชำระเงิน', "success", "ตกลง").then(() => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  });
                } else {
                  SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                    () => {
                      liff.ready.then(() => {
                        liff.closeWindow();
                      });
                    }
                  );
                }
              })
              .catch((error) => {
                if (error.response) {
                  let errors = error.response.data.errors;

                  if (errors.id_line) {
                    SToast("error", `${errors.id_line[0]}`);
                  } else {
                    SToast("error", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                  }
                }
              });
            //outer.push("/Home")
          }
        });
      } else {
        SconfirmNc("กรุณาเลือกธนาคารที่ชำระ", "error", "ตกลง")
      }

    }


    const selectImage = (e) => {
      if (e === 1) {
        document.getElementById("getFilecamera").click();
      } else {
        document.getElementById("getFile").click();
      }
    };

    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };

    const pickFile = async (e) => {
      const files = e.target.files;
      if (files.length <= 1) {
        if (imageUrl.value.length < 1) {
          for (var i = 0; i < files.length; i++) {
            imageUrl.value.push(
              await reduce_image_file_size(await image_to_base64(files[i]))
            );
          }
        } else {
          SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
        }
      } else {
        SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
      }
    };


    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }




    return {
      Order_detail,
      SendLocation,
      isLoading,
      color,
      addSendLocation,
      back,
      pay,
      ktb,
      scb,
      select_bank,
      toggle_confirm_slip,
      confirm_slip,
      selectImage,
      pickFile,
      deleteImage,
      validation_data,
      imageUrl,
      pay_date,
      pay_time,
      onSubmitData

    };
  },
});
</script>

<style scoped>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* Button styles */
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.icon {
  width: 60px;
  padding: 5px;
}

.gender {
  padding: 16px 25px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}


input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}


boder-box {
  border: 2px solid var(--color);
  padding: 5px;
}

boder-box {
  border: 2px solid var(--color);
  padding: 5px;
}

.cart-pic {
  height: 100%;
  width: 100px;
}

.button-box {
  display: flex;
  height: 30px;
  width: 110px;
  border: solid 1px #919191;
  padding: 5px;
  border-radius: 20px;
  justify-content: space-between;
}

.button-box button {
  text-align: center;
  background-color: white;
  border: solid 1px #919191;
  border-radius: 50%;
  font-size: 16px;
  line-height: 2px;
  text-align: center;
}

.count-item {
  line-height: 20px;
  font-size: 18px;
  width: 30px;
  text-align: center;
}

button-box button {
  text-align: center;
  border: solid 1px #070707;
  border-radius: 50%;
}

.cart-box {
  display: flex;
  height: 50px;
  width: 100%;
  flex-direction: row;
}

/* .cart-box image {
  position: absolute;
  top: 0;
  left: 25px;
  width: 84px;
  height: 67px;
} */

.form-check-label {
  font-size: 16px !important;
}

.sub_header {
  padding: 0;
  width: 100%;
  height: 60px;
  min-height: 60px;
}

.sub_body {
  padding: 16px;
}


.logo_sub {
  position: absolute;
  top: 10px;
  left: 20px;
}

.close_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  right: 10px;
}

.back_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  left: 10px;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: var(--color);
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #8b9f6d;
}

.bt-main:active {
  background-color: #8b9f6d;
  transition: background-color 0s;
  opacity: 0.5;
}

.bt-sub-outline {
  width: 30%;
  min-height: 30px;
  border: solid 1px var(--color_sub);
  background-color: white;
  color: var(--color_sub);
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-sub-outline:hover {
  background-color: #f3f3f3;
}


.bt-sub-outline:active {
  background-color: #f3f3f3;
  transition: background-color 0s;
  opacity: 0.5;
}


.product-title-text {
  font-size: 1.5em;
  font-weight: 400;
}

.product-subtitle-text {
  font-size: 1.2em;
  font-weight: 400;
}

.product-detail-text {
  font-size: 1.0em;
  font-weight: 200;
}

.product-sammary-text {
  font-size: 2.5em;
  font-weight: 500;
}


.bt-edit {
  width: 100%;
  min-height: 60px;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  border: 1px solid #1d1c1d4d;

}

.bt-edit:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
}

.bt-edit:active {
  background-color: #e9e9e9;
  transition: background-color 0s;
  opacity: 0.5;
}

.button-upload {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
  height: 80px !important;
}

.button-upload:hover,
.button-upload:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-upload:hover {
  transform: translateY(-1px);
}

.button-upload:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.button-submit {
  font-family: Kanit, sans-serif;
  font-weight: 500;
  font-size: 22px !important;
  border: 0px solid var(--color);
  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff;
  background-color: #6C8154 !important;
  height: 40px;
  width: 120px;
  min-height: 44px !important;
  padding: 0 14px !important;
  letter-spacing: 0;
  text-indent: 0;
  text-transform: none;
  flex: 1;
}

.button-submit {
  background-color: inherit;
  /* Green */


}

.images {
  width: 100%;
}

.xx {
  position: absolute;
  background-color: #b92735;
  color: white;
  right: 11px;
  padding: 6px;
}
</style>
