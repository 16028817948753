<script>
import { provide, computed, ref } from "vue";

export default {
  name: "Tabs",
  props: {
    modelValue: {
      type: [String, Number],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const active = computed(() => props.modelValue);
    const tabs = ref([]);

    function selectTab(tab) {
      emit("update:modelValue", tab);
    }

    provide("tabsState", {
      active,
      tabs,
      selectTab,
    });
  },
};
</script>
<style scoped>
.flex {
  display: flex;
}

.bg-gray-300 {
  background-color: gray;
  margin: -3px;
}
.h-1 {
  height: 2px;
}
</style>
<template>
  <div class="flex" style="font-size: 14px;">
    <slot />
  </div>
  <div class="bg-gray-300 -m-1 h-1"></div>
</template>
