<template>
  <div class="vld-parent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="fullPage" loader="dots" :color="color"></loading>
    <div class="card-body" :style="{ 'background-color': color }">
      <div class="p-10">
        <tabs v-model="active">
          <tab>รอชำระเงิน<span v-if="Order_wait_payment.length > 0">({{ Order_wait_payment.length }})</span></tab>
          <tab>รอยืนยันการชำระเงิน<span v-if="Order_wait_confirm_payment.length > 0">({{
            Order_wait_confirm_payment.length
          }})</span>
          </tab>
          <tab>พร้อมจัดส่ง<span v-if="Order_ready.length > 0">({{ Order_success.length }})</span></tab>
          <tab>จัดส่งสำเร็จ<span v-if="Order_success.length > 0">({{ Order_success.length }})</span></tab>
          <tab>ยกเลิก<span v-if="Order_cancle.length > 0">({{ Order_cancle.length }})</span></tab>
        </tabs>
      </div>
    </div>

    <tab-panels v-model="active">



      <tab-panel>
        <template v-if="Order_wait_payment.length > 0">
          <div class="mb-3">
            <div class="card-body mt-3 white" v-for="(data, index) in Order_wait_payment" :key="index">
              <div class="history">
                <div class="history-header">
                  <div class="history-title"> หมายเลขคำสั่งซื้อ : {{ data.order_no }}</div>
                  <div class="history-status"> {{ dateThai(data.created_at) }}</div>
                </div>
                <div class="history-footer">
                  <button @click="go_cancleOrder(data.id)" type="button" class="btn out-line">
                    ยกเลิก
                  </button>
                  <!-- <button @click="setOrderdetail(data)" type="button" class="btn out-line">
                    รายละเอียด
                  </button> -->
                  <button @click="setOrderdetail(data)" type="button" class="btn primary">
                    ชำระเงิน
                  </button>

                </div>

              </div>
              <div class="d-flex" style="height:1px; background-color: #D9D9D9; width: 100% !important;"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>

      <tab-panel>
        <template v-if="Order_wait_confirm_payment.length > 0">
          <div class="mb-3">
            <div class="card-body mt-3 white" v-for="(data, index) in Order_wait_confirm_payment" :key="index">
              <div class="history">
                <div class="history-header">
                  <div class="history-title"> หมายเลขคำสั่งซื้อ : {{ data.order_no }}</div>
                  <div class="history-status"> {{ dateThai(data.created_at) }}</div>
                </div>

                <div class="history-footer">
                  <button @click="setOrderdetail(data)" type="button" class="btn out-line">
                    รายละเอียด
                  </button>
                </div>
              </div>
              <div class="d-flex" style="height:1px; background-color: #D9D9D9; width: 100% !important;"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>

      <tab-panel>
        <template v-if="Order_ready.length > 0">
          <div class="mb-3">
            <div class="card-body mt-3 white" v-for="(data, index) in Order_ready" :key="index">
              <div class="history">
                <div class="history-header">
                  <div class="history-title"> หมายเลขคำสั่งซื้อ : {{ data.order_no }}</div>
                  <div class="history-status"> {{ dateThai(data.created_at) }}</div>
                  <div class="history-subtitle">เวลาจัดส่ง : {{ dateThai(data.created_at) }}</div>

                </div>
                <div class="history-footer">

                  <button @click="setOrderdetail(data)" type="button" class="btn out-line">
                    รายละเอียด
                  </button>
                </div>

              </div>
              <div class="d-flex" style="height:1px; background-color: #D9D9D9; width: 100% !important;"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>

      <tab-panel>
        <template v-if="Order_success.length > 0">
          <div class="mb-3">
            <div class="card-body mt-3 white" v-for="(data, index) in Order_success" :key="index">
              <div class="history">
                <div class="history-header">
                  <div class="history-title"> หมายเลขคำสั่งซื้อ : {{ data.order_no }}</div>
                  <div class="history-status"> {{ dateThai(data.created_at) }}</div>
                </div>
                <div class="history-footer">
                  {{ data.product }}
                  <button @click="setOrderdetail(data)" type="button" class="btn out-line">
                    รายละเอียด
                  </button>


                </div>

              </div>
              <div class="d-flex" style="height:1px; background-color: #D9D9D9; width: 100% !important;"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>

      <tab-panel>
        <template v-if="Order_cancle.length > 0">
          <div class="mb-3">
            <div class="card-body mt-3 white" v-for="(data, index) in Order_cancle" :key="index">
              <div class="history">
                <div class="history-header">
                  <div class="history-title"> หมายเลขคำสั่งซื้อ : {{ data.order_no }}</div>
                  <div class="history-status"> {{ dateThai(data.created_at) }}</div>
                </div>
                <div class="history-footer">

                  <button @click="setOrderdetail(data)" type="button" class="btn out-line">
                    รายละเอียด
                  </button>

                </div>
              </div>
              <div class="d-flex" style="height:1px; background-color: #D9D9D9; width: 100% !important;"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>

    </tab-panels>
  </div>
</template>

<script>
//computed
import liff from "@line/liff";
import { defineComponent, onMounted, ref, inject, computed } from "vue";
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import TabPanels from "@/components/TabPanels";
import TabPanel from "@/components/TabPanel";
import moment from "moment";
import useOrder from "@/service/api/Order";
import Loading from "vue-loading-overlay";
import { SET_ORDER_DETAIL } from "@/store/modules/OrderModel";
import "vue-loading-overlay/dist/vue-loading.css";
import useSweetalert from "@/service/sweetalert2";

export default defineComponent({
  name: "Orderhistory",
  components: {
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
    Loading,
  },
  setup() {

    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { getOrder_by_id, cancleOrder } = useOrder();
    const store = inject("store");
    const router = inject("router");
    const active = ref(0);
    const isLoading = ref(true);
    const fullPage = ref(false);
    const orders = ref([]);
    const color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      } else {
        getOrder_by_id(store.getters.getAccessToken.accesstoken).then((res) => {
          orders.value = res.data.data;
          isLoading.value = false;
        });
      }
    });

    function dateThai(date) {
      let y = moment(date).format("yyy");
      let year = parseInt(y) + 543;
      let march = moment(date).locale("th");
      // console.log(march.format("dddที่ D MMMM พ.ศ. " + year + " เวลา HH : mm : ss"));
      return march.format("dddที่ D MMMM พ.ศ. " + year + " เวลา HH : mm : ss");

    }



    const Order_wait_payment = computed(() => {
      return orders.value.filter((item) => item.order_status_name === "รอชำระเงิน");
    });

    const Order_wait_confirm_payment = computed(() => {
      return orders.value.filter((item) => item.order_status_name === "รอยืนยันการชำระเงิน");
    });

    const Order_ready = computed(() => {
      return orders.value.filter((item) => item.order_status_name === "พร้อมจัดส่ง");
    });

    const Order_success = computed(() => {
      return orders.value.filter((item) => item.order_status_name === "จัดส่งสำเร็จ");
    });

    const Order_cancle = computed(() => {
      return orders.value.filter((item) => item.order_status_name === "ยกเลิก");
    });

    const setOrderdetail = (data) => {
      //console.log(data);
      store.dispatch(SET_ORDER_DETAIL, data);

      //console.log(store.getters.getBookingdetail);
      router.push({ name: "Detail" });
    };

    const goPayment= (data) => {
      //console.log(data);
      store.dispatch(SET_ORDER_DETAIL, data);

      //console.log(store.getters.getBookingdetail);
      router.push({ name: "Payment" });
    };

    



    const go_cancleOrder = (id) => {

      let send_data = {};
      send_data.id_line = store.getters.getAccessToken.accesstoken;
      send_data.id = id;


      console.log(send_data);

      Sconfirm("ยืนยันการยกเลิกออร์เดอร์?", "question", "ยกเลิก", "ตกลง").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await cancleOrder(send_data)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "Success") {
                SconfirmNc("ยกเลิกออร์เดอร์สำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }

                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
          //outer.push("/Home")
        }
      });



    }


    return {
      active,
      dateThai,
      Order_wait_payment,
      Order_wait_confirm_payment,
      Order_ready,
      Order_success,
      Order_cancle,
      isLoading,
      fullPage,
      color,
      go_cancleOrder,
      cancleOrder,
      orders,
      setOrderdetail,
      goPayment
    };
  },
});
</script>

<style scoped>
.card-body {
  padding: 0;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}

.title-text {
  font-size: 12px;
  margin-right: 5px;
}

.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}

.body-text {
  height: 16rem;
  position: relative;
}

.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}

.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}

.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 25%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}

.appeal-image {
  position: relative;
}

.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}

.history-image {
  position: relative;
}

.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}

.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}

.history-header {

  padding: 10px;
  display: flex;
  flex-direction: column;
}

.history-title {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-align: start;
}

.history-subtitle {
  width: 100%;
  font-size: 14px;
  text-align: start;
}

.history-status {
  width: 100%;
  font-size: 14px;
  text-align: start;
}

.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}

.history-detail {
  margin-left: 10px;
}

.history-footer {
  width: 100%;
  font-size: 12px;
  padding: 10px;
  display: flex;
  position: relative;
  justify-content: end;
  gap: 5px;
}

.history-botton {
  position: absolute;
  right: 10px;
  bottom: 6px;
}

.history-botton2 {
  position: absolute;
  right: 85px;
  bottom: 6px;
}

.history-botton3 {
  position: absolute;
  right: 180px;
  bottom: 6px;
}

.out-line {
  background-color: white !important;
  border-color: var(--color) !important;
  color: var(--color) !important;
}

.primary {
  background-color: var(--color) !important;
  color: white !important;
}

.history-empty {
  position: relative;
  height: 500px;
}

.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}

.history {}
</style>
